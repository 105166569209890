import React, { useEffect, useState } from "react";
import "./CreateFoodItems.css";
import { Button, CreateFoodHeader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateFoodItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { baseUrl, getUserDetails, userDetails } = useGlobalContext();
  const adminToken = JSON.parse(sessionStorage.getItem("adminToken"));
  const [loading, setLoading] = useState(false);
  const [otpLoader, setotpLoader] = useState(false);
  const [profit, setprofit] = useState(false);
  const [transferStep, setTransferStep] = useState("");
  const [userOtp, setuserOtp] = useState("");
  const [loadingTransfer, setLoadingT] = useState(false);
  const notify = () => toast.success("User Details Updated");

  const navigateBack = () => {
    navigate(`/user-details/${id}`);
  };

  const handleTransferStep = () => {
    setLoadingT(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { transferStep: transferStep },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingT(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingT(false);
      });
  };
  const handleProfit = () => {
    setLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { profit: profit },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoading(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleUserOtp = () => {
    setotpLoader(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { userOtp },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setotpLoader(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setotpLoader(false);
      });
  };

  useEffect(() => {
    getUserDetails(id);
  }, []);

  return (
    <section className="add_food_item_container">
      <CreateFoodHeader
        btn={false}
        navigateBack={navigateBack}
        title={"Update User Details"}
      />
      <div className="add_food_item_form">
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Profit</p>
          <input
            type="text"
            name="profit"
            className="food_item_inputs"
            placeholder="Profit"
            onChange={(e) => setprofit(e.target.value)}
            defaultValue={userDetails.profit}
          />
        </div>
        <Button
          icon={
            loading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Profit"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleProfit}
        />
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Code</p>
          <input
            type="text"
            name="profit"
            className="food_item_inputs"
            placeholder="Profit"
            onChange={(e) => setuserOtp(e.target.value)}
            defaultValue={userDetails.userOtp}
          />
        </div>
        <Button
          icon={
            otpLoader && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Code"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleUserOtp}
        />

        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Withdrawal Step</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="Withdrawal Step"
            onChange={(e) => setTransferStep(e.target.value)}
            defaultValue={userDetails.transferStep}
          />
        </div>
        <Button
          icon={
            loadingTransfer && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Withdrawal Step"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleTransferStep}
        />
      </div>
      <ToastContainer />
    </section>
  );
};

export default CreateFoodItems;
